import rmfmSwap from './audio/rmfam-swap.jpg';
import { UserSession } from '@stacks/auth';
import {
  openContractCall,
} from '@stacks/connect';

import {
  contractPrincipalCV, uintCV, Pc, NonFungibleConditionCode, PostConditionMode, Cl
} from '@stacks/transactions';


import { STACKS_MAINNET, STACKS_TESTNET } from '@stacks/network';


// nft-swap.js
const NFTSwapModule = {
  styles: `
    .card {
      font-family: 'Helvetica';
      background: white;
      border-radius: 0.5rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12);
      padding: 1.5rem;
      margin-bottom: 1rem;
    }

    .nft-grid {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      gap: 1rem;
      margin: 1rem 0;
    }

    .nft-item {
      border: 2px solid #e2e8f0;
      border-radius: 0.5rem;
      padding: 0.5rem;
      cursor: pointer;
      transition: all 0.2s;
    }

    .nft-item.selected {
      border-color: #3b82f6;
      background: #eff6ff;
    }

    .nft-item img {
      width: 100%;
      height: auto;
      border-radius: 0.25rem;
    }

    .button {
      background: #3b82f6;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      border: none;
      font-weight: 500;
      cursor: pointer;
      transition: background 0.2s;
    }

    .button:disabled {
      background: #94a3b8;
      cursor: not-allowed;
    }

    .summary-section {
      background: #f8fafc;
      border-radius: 0.5rem;
      padding: 1rem;
      margin: 1rem 0;
    }

    .progress-steps {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
    }

    .step {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .step-number {
      background: #e2e8f0;
      color: #64748b;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .step.active .step-number {
      background: #3b82f6;
      color: white;
    }
       .token-summary {
    padding: 1rem;
    background: white;
    border-radius: 0.5rem;
  }

  .token-amount {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .fee-details {
    border-top: 1px solid #e2e8f0;
    padding-top: 1rem;
  }

  .fee-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .fee-item.total {
    font-weight: bold;
    font-size: 1.1rem;
  }

  .button-group {
    display: flex;
    gap: 1rem;
    margin-top: 1rem;
  }

  .button-secondary {
    background: #e2e8f0;
    color: #1f2937;
  }

  hr {
    border: none;
    border-top: 1px solid #e2e8f0;
    margin: 0.5rem 0;
  }
  
  .cover-image {
  width: 100%;
  height: 200px;
  margin-bottom: 2rem;
  overflow: hidden;
  border-radius: 0.5rem;
}

.cover-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
  `,

init: function(container, router) {
    // Add styles to document if not already added
    if (!document.querySelector('#nft-swap-styles')) {
      const styleSheet = document.createElement('style');
      styleSheet.id = 'nft-swap-styles';
      styleSheet.textContent = this.styles;
      document.head.appendChild(styleSheet);
    }
  return new NFTSwapComponent(container, router);
  }
};


function NFTSwapComponent(container, router) {
  this.router = router;
  this.container = container;
  this.selectedNFTs = new Set();
  this.maxSelection = 5;
  this.tokenRate = 15;
  this.init();
}

// Add to NFTSwapComponent


NFTSwapComponent.prototype = {
  init() {
    console.log('Initializing NFT swap component');
    this.checkWalletConnection();
  },


  checkWalletConnection() {
    try {
      if (!this.router) {
        console.error('Router not initialized');
        this.renderError('Router not initialized');
        return;
      }

      if (!this.router.auth) {
        console.error('Auth not initialized in router');
        this.renderError('Authentication not initialized');
        return;
      }

      const isConnected = this.router.auth.isSignedIn();
      console.log('Wallet connection status:', isConnected);

      if (!isConnected) {
        this.renderConnectButton();
      } else {
        this.render();
        this.initializeElements();
        this.bindEvents();
        this.loadNFTs();
      }
    } catch (error) {
      console.error('Error checking wallet connection:', error);
      this.renderError('Failed to check wallet connection');
    }
  },

  renderError(message) {
    if (!this.container) return;

    this.container.innerHTML = `
      <div class="card">
        <div class="p-6 text-center">
          <h2 class="text-xl font-bold mb-4">Error</h2>
          <p class="text-red-500 mb-4">${message}</p>
          <button id="retry-button" class="button">Retry</button>
        </div>
      </div>
    `;

    const retryBtn = this.container.querySelector('#retry-button');
    if (retryBtn) {
      retryBtn.addEventListener('click', () => this.init());
    }
  },

  renderConnectButton() {
    if (!this.container) return;

    this.container.innerHTML = `
      <div class="card">
        <div class="cover-image">
          <img src="${rmfmSwap}" alt="RMFAM Swap Cover" />
        </div>
        <div class="text-center p-6">
          <h2 class="text-2xl font-bold mb-4">Sell your NFTs</h2>
          <p class="mb-6">Swap your RMFAM NFTs for RMFAM tokens</p>
          <div class="connect-wallet">
            <button class="button" id="connect-wallet-btn">
              Connect Wallet to begin
            </button>
          </div>
        </div>
      </div>
    `;

    const connectBtn = this.container.querySelector('#connect-wallet-btn');
    if (connectBtn) {
      connectBtn.addEventListener('click', async () => {
        try {
          if (!this.router?.auth?.connectWallet) {
            throw new Error('Wallet connection method not available');
          }

          console.log('Attempting to connect wallet');
          await this.router.auth.connectWallet();
          console.log('Wallet connection successful');

          // After successful connection, check wallet state and proceed
          this.checkWalletConnection();
        } catch (error) {
          console.error('Error connecting wallet:', error);
          // Show error to user
          const errorMessage = document.createElement('div');
          errorMessage.className = 'text-red-500 mt-4';
          errorMessage.textContent = `Failed to connect: ${error.message}`;
          const errorContainer = connectBtn.parentNode;
          // Remove any existing error messages
          const existingError = errorContainer.querySelector('.text-red-500');
          if (existingError) {
            errorContainer.removeChild(existingError);
          }
          errorContainer.appendChild(errorMessage);
        }
      });
    }
  },


  async calculateTokenReward(nftCount) {
    // Constants
    const LAUNCH_STX_USD = 1.4904;  // STX price at launch (9/16/2024)
    const BASE_RMFAM_REWARD = 15;
    const minReward = 5;  // Minimum tokens per NFT
    const maxReward = 30; // Maximum tokens per NFT



    try {
      // Get current STX price from API (example using CoinGecko)
      const response = await fetch('https://api.diadata.org/v1/assetQuotation/Stacks/0x0000000000000000000000000000000000000000');
      const data = await response.json();
      const currentSTXPrice = data.Price;
    
      const priceRatio = LAUNCH_STX_USD / currentSTXPrice;
      const adjustedReward = Math.floor(BASE_RMFAM_REWARD * priceRatio);
      const boundedReward = Math.min(Math.max(adjustedReward, minReward), maxReward);

      console.log({
        currentSTXPrice,
        priceRatio,
        adjustedReward,
        boundedReward
      });


      return boundedReward * nftCount;
    } catch (error) {
      console.error('Error fetching STX price:', error);
      return BASE_RMFAM_REWARD * nftCount; // Fallback to base reward
    }
  },

  async showReviewScreen() {
    const tokenAmount = await this.calculateTokenReward(this.selectedNFTs.size);

    this.container.innerHTML = `
    <div class="card">
      <div class="progress-steps">
        <div class="step">
          <div class="step-number">1</div>
          <span>Select NFTs</span>
        </div>
        <div class="step active">
          <div class="step-number">2</div>
          <span>Review</span>
        </div>
        <div class="step">
          <div class="step-number">3</div>
          <span>Confirm</span>
        </div>
      </div>

      <div id="review-view">
        <h2>Review Your Swap</h2>
        <p>Please review the details of your swap before confirming</p>

        <div class="summary-section">
          <h3>You're Sending</h3>
          <div class="nft-grid" id="selected-nfts">
            ${Array.from(this.selectedNFTs).map(id => `
              <div class="nft-item">
                <img src="https://ipfs.io/ipfs/QmWLihA4syDM7sCP1e3p83XvBJdN5uz2p4hNA2UKkrT3Af/images/NFT_${id.padStart(4, '0')}.png" alt="RMFAM #${id}">
               <p>RMFAM #${id}</p>
              </div>
            `).join('')}
             
            </div>
             <div class="fee-item">
                <span>Platform Fee:</span>
                <span>0.1 STX</span>
              </div>
              <hr>
              <div class="fee-item total">
                <span>Total Fee:</span>
                <span>0.1 STX</span>
              </div>
          </div>
        </div>

        <div class="summary-section">
          <h3>You'll Receive</h3>
          <div class="token-summary">
             <p class="token-amount">${tokenAmount} RMFAM tokens</p>
            <div class="fee-details">
            
          </div>
        </div>

        <div class="button-group">
          <button id="back-btn" class="button button-secondary">Back</button>
          <button id="confirm-btn" class="button">Begin Swap</button>
        </div>
      </div>
    </div>
  `;

    // Update bindings for new buttons
    this.backBtn = this.container.querySelector('#back-btn');
    this.confirmBtn = this.container.querySelector('#confirm-btn');

    this.backBtn.addEventListener('click', () => this.init());
    this.confirmBtn.addEventListener('click', () => this.showConfirmScreen());
  },


  async showConfirmScreen() {
    const tokenAmount = await this.calculateTokenReward(this.selectedNFTs.size);

    this.container.innerHTML = `
        <div class="card">
            <div class="progress-steps">
                <div class="step">
                    <div class="step-number">1</div>
                    <span>Select NFTs</span>
                </div>
                <div class="step">
                    <div class="step-number">2</div>
                    <span>Review</span>
                </div>
                <div class="step active">
                    <div class="step-number">3</div>
                    <span>Confirm</span>
                </div>
            </div>

            <div id="confirm-view">
                <div class="text-center mb-6">
                    <h2 class="text-2xl font-bold mb-2">Confirming Swap</h2>
                    <p>Please confirm this transaction in your wallet</p>
                </div>

                <div class="summary-section">
                    <div class="token-summary p-4 bg-gray-50 rounded">
                        <h3 class="font-bold mb-2">Transaction Summary</h3>
                        <div class="fee-item mb-2">
                            <span>Swapping:</span>
                            <span>${Array.from(this.selectedNFTs).length} NFTs</span>
                        </div>
                        <div class="fee-item mb-2">
                            <span>Receiving:</span>
                            <span>${tokenAmount} RMFAM tokens</span>
                        </div>
                        <div class="fee-item">
                            <span>Network Fee:</span>
                            <span>0.1 STX</span>
                        </div>
                    </div>
                </div>

                <div class="status-section mt-6 text-center" id="status-container">
                    <div class="spinner mb-4">⏳</div>
                    <p>Waiting for wallet confirmation...</p>
                </div>

                <div class="button-group mt-6">
                    <button id="cancel-btn" class="button button-secondary">Cancel</button>
                </div>
            </div>
        </div>
    `;

    const cancelBtn = this.container.querySelector('#cancel-btn');
    cancelBtn.addEventListener('click', () => this.init());

    try {
      const statusContainer = this.container.querySelector('#status-container');
      let allTransactionsSuccessful = true;

      for (const nftId of this.selectedNFTs) {
        try {
          statusContainer.innerHTML = `
                    <div class="mb-4">
                        <div class="spinner">🔄</div>
                        <p>Processing NFT #${nftId}...</p>
                    </div>
                `;

          // Contract details
          const contractAddress = 'SPMYMHHC95YE7EZEF19NNWSZMHXEJ97ZYHJB5NXV';
          const contractName = 'rmfam-nft-swap';
          const functionName = 'sell-nft-to-deployer';
          const nftContractAddress = 'SP3NJ4BR35W8002J0PWZY0QNG9FTYZ32H38Z0PV17';
          const nftContractName = 'rise-of-the-meme';
          const ftcontractAddress = 'SP3SMQNVWRBVWC81SRJYFV4X1ZQ7AWWJFBQJMC724';
          const ftcontractName = 'riseofthememefam';
          const userAddress = this.router.getCurrentStxAddress();

          const postConditions = [
            {
              type: 'nft-postcondition',
              address: userAddress,
              condition: 'sent',
              asset: 'SP3NJ4BR35W8002J0PWZY0QNG9FTYZ32H38Z0PV17.rise-of-the-meme::rise-of-the-meme',
              assetId: Cl.uint(nftId),
            },
            {
              type: 'stx-postcondition',
              address: userAddress,
              condition: 'gte',
              amount: '100000',
            }
          ];

          const options = {
            contractAddress,
            contractName,
            functionName,
            functionArgs: [
              contractPrincipalCV(nftContractAddress, nftContractName),
              uintCV(parseInt(nftId)),
              uintCV(tokenAmount * 1_000_000),
              contractPrincipalCV(ftcontractAddress, ftcontractName)
            ],
            postConditions,
            network: window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet',
            appDetails: {
              name: "RMFAM NFT Swap",
              icon: window.location.origin + "/icon.png"
            },
            onFinish: (data) => {
              console.log('Transaction finished:', data);
            }
          };

          const response = await openContractCall(options);

          if (!response) {
            throw new Error('No response from contract call');
          }

          console.log('Transaction finished:', response);

          if (response.txId) {
            statusContainer.innerHTML = `
                        <div class="mb-4">
                            <div class="spinner">🔄</div>
                            <p>Transaction submitted for NFT #${nftId}...</p>
                            <p class="text-sm">Transaction ID: ${response.txId}</p>
                        </div>
                    `;

            const result = await waitForTransaction(response.txId);
            console.log('Transaction result:', result);

            statusContainer.innerHTML = `
                        <div class="mb-4">
                            <div class="success-icon">✅</div>
                            <p>Transaction confirmed for NFT #${nftId}</p>
                            <p class="text-sm">Transaction ID: ${response.txId}</p>
                        </div>
                    `;
          } else {
            throw new Error('Transaction ID not found in response');
          }

        } catch (error) {
          console.error(`Error processing NFT #${nftId}:`, error);
          allTransactionsSuccessful = false;
          throw error;
        }
      }

      if (allTransactionsSuccessful) {
        statusContainer.innerHTML = `
                <div class="success-message">
                    <div class="mb-4">✅</div>
                    <h3 class="font-bold mb-2">Swap Complete!</h3>
                    <p>Your NFTs have been swapped successfully</p>
                    <button id="done-btn" class="button mt-4">Done</button>
                </div>
            `;

        const doneBtn = statusContainer.querySelector('#done-btn');
        doneBtn.addEventListener('click', () => this.init());
      }

    } catch (error) {
      console.error('Swap failed:', error);
      const statusContainer = this.container.querySelector('#status-container');
      statusContainer.innerHTML = `
            <div class="error-message text-red-500">
                <div class="mb-4">❌</div>
                <h3 class="font-bold mb-2">Swap Failed</h3>
                <p>${error.message || 'Transaction failed to complete'}</p>
                <button id="retry-btn" class="button mt-4">Try Again</button>
            </div>
        `;

      const retryBtn = statusContainer.querySelector('#retry-btn');
      retryBtn.addEventListener('click', () => this.showReviewScreen());
    }
  },

  // Helper function to wait for transaction confirmation

  async fetchNFTMetadata(ipfsUrl) {
    try {
      const httpUrl = ipfsUrl.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/');
      const response = await fetch(httpUrl);
      if (!response.ok) throw new Error('Failed to fetch metadata');

      const metadata = await response.json();
      return metadata;
    } catch (error) {
      console.error('Error fetching metadata:', error);
      return null;
    }
  },

  render() {
    if (!this.container) return;

    const isConnected = this.router?.auth?.isSignedIn();
    if (!isConnected) {
      this.renderConnectButton();
      return;
    }

    this.container.innerHTML = `
      <div class="card">
       <div class="cover-image">
        <img src="${rmfmSwap}" alt="RMFAM Swap Cover" />
      </div>
        <div class="progress-steps">
          <div class="step active">
            <div class="step-number">1</div>
            <span>Select NFTs</span>
          </div>
          <div class="step">
            <div class="step-number">2</div>
            <span>Review</span>
          </div>
          <div class="step">
            <div class="step-number">3</div>
            <span>Swap</span>
          </div>
        </div>

        <div id="selection-view">
          <h2>Select RMFAM NFTs to Swap</h2>
          <p>Select 1 NFT to swap for RMFAM tokens</p>
          
          <div class="nft-grid" id="nft-list">
            <!-- NFTs will be populated here -->
          </div>

          <div class="summary-section">
            <h3>Summary</h3>
            <p>Selected: <span id="selected-count">0</span>/1 NFTs</p>

             <div class="process-explanation mt-4 p-4 bg-gray-50 rounded">
            <h4 class="font-semibold mb-2">How it works:</h4>
                <class="list-decimal pl-5 space-y-2" style="
                    color: #222;
                    line-height: 2;
                ">
                <li>Your RMFAM NFT will be securely placed in a vault</li>
                <li>Buyer has 24 hours to accept your swap offer</li>
                <li>If accepted, you receive <span id="token-amount">0</span> RMFAM tokens</li>
    
            </ol>
        </div>

            <p>You will send: 1 RMFAM NFT. </p>
            <p>You will receive: <span id="token-amount">0</span> RMFAM tokens.</p>
          </div>
          <div class="button-group">
          <button id="continue-btn" class="button" disabled>Continue to Review</button>
          </div>
        </div>
      </div>
    `;
  },

  bindEvents() {
    // Check if elements exist before binding
    if (this.continueBtn) {
      this.continueBtn.addEventListener('click', () => this.continueToReview());
    }
  },


  initializeElements() {
    this.nftList = this.container.querySelector('#nft-list');
    this.selectedCount = this.container.querySelector('#selected-count');
    this.tokenAmount = this.container.querySelector('#token-amount');
    this.continueBtn = this.container.querySelector('#continue-btn');
  },


  async loadNFTs() {
    try {
      const nfts = await this.fetchWalletNFTs();
      this.renderNFTs(nfts);
    } catch (error) {
      console.error('Error loading NFTs:', error);
    }
  },

  async fetchWalletNFTs() {
    try {
    

      if (!this.router) {
        console.error('Router not initialized in NFT swap component');
        return [];
      }

      const userAddress = this.router.getCurrentStxAddress();

      if (!userAddress) {
        console.error('No wallet address found - please connect wallet');
        return [];
      }

      console.log('Fetching NFTs for address:', userAddress);


      const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddress}&limit=50`;
      console.log('Fetching NFTs from URL:', nftHoldingsUrl);

      const response = await fetch(nftHoldingsUrl);
   
      if (!response.ok) {
        console.error('API response not OK:', response.status, response.statusText);
        return [];
      }

      const data = await response.json();
      console.log('Raw NFT data received:', data);

      if (!data.results?.length) {
        console.log('No NFTs found in API response');
        return [];
      }
      const contractInfo = {
        contractAddress: 'SP3NJ4BR35W8002J0PWZY0QNG9FTYZ32H38Z0PV17',
        assetName: 'rise-of-the-meme'
      };

      // Filter and fetch metadata for NFTs
      console.log('Filtering NFTs with contract:', contractInfo);

      const ownedNFTs = data.results.filter(nft =>
        nft.asset_identifier.includes(contractInfo.contractAddress) &&
        nft.asset_identifier.includes(contractInfo.assetName)
      );

      // Fetch metadata for all NFTs
      const nftsWithMetadata = await Promise.all(ownedNFTs.map(async (nft) => {
        const tokenId = nft.value.repr.replace('u', '');
        const ipfsUrl = `ipfs://ipfs/QmQbe4QEmw5HbCvxLtLQirT6JfdJDi9cJRqNBdLvf2x8X4/json/${tokenId}.json`;

        try {
          const metadata = await this.fetchNFTMetadata(ipfsUrl);
          const imageUrl = metadata?.image ?
            metadata.image.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/') :
            `/api/placeholder/150/150`; // Fallback to placeholder if no image

          return {
            id: tokenId,
            name: metadata?.name || `Rise of the Meme #${tokenId}`,
            image: imageUrl
          };
        } catch (error) {
          console.error(`Error fetching metadata for token ${tokenId}:`, error);
          return null;
        }
      }));

      // Filter out any failed metadata fetches
      return nftsWithMetadata.filter(nft => nft !== null);

    } catch (error) {
      console.error('Error fetching NFTs:', error);
      return [];
    }
  },

  
  renderNFTs(nfts) {
    this.nftList.innerHTML = nfts.map(nft => `
      <div class="nft-item" data-id="${nft.id}">
        <img src="${nft.image}" alt="${nft.name}">
        <p>${nft.name}</p>
      </div>
    `).join('');

    this.nftList.addEventListener('click', (e) => {
      const nftItem = e.target.closest('.nft-item');
      if (nftItem) {
        this.toggleNFTSelection(nftItem);
      }
    });
  },

  toggleNFTSelection(nftItem) {
    const nftId = nftItem.dataset.id;

    if (this.selectedNFTs.has(nftId)) {
      this.selectedNFTs.delete(nftId);
      nftItem.classList.remove('selected');
    } else if (this.selectedNFTs.size < this.maxSelection) {
      this.selectedNFTs.add(nftId);
      nftItem.classList.add('selected');
    }

    this.updateSummary();
  },

  async updateSummary() {
    const count = this.selectedNFTs.size;
    this.selectedCount.textContent = count;
    const tokenAmount = await this.calculateTokenReward(count);
    this.tokenAmount.textContent = tokenAmount;
    this.continueBtn.disabled = count === 0;
  },

  async continueToReview() {
    if (this.selectedNFTs.size > 0) {
      // Implement review screen transition
      console.log('Selected NFTs:', Array.from(this.selectedNFTs));
      this.showReviewScreen();
    }
  }
};

async function waitForTransaction(txId) {
  const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
  const apiUrl = network === 'mainnet'
    ? 'https://stacks-node-api.mainnet.stacks.co'
    : 'https://stacks-node-api.testnet.stacks.co';

  while (true) {
    const response = await fetch(`${apiUrl}/extended/v1/tx/${txId}`);
    const data = await response.json();

    if (data.tx_status === 'success') {
      return data;
    } else if (data.tx_status === 'failed') {
      throw new Error('Transaction failed');
    }

    // Wait 10 seconds before checking again
    await new Promise(resolve => setTimeout(resolve, 10000));
  }
}

// Make it available globally
window.NFTSwapModule = NFTSwapModule;