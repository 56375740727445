
import {
fetchCallReadOnlyFunction
} from '@stacks/transactions';
import { bufferCVFromString, noneCV, addressToString, Cl, deserializePrincipal, standardPrincipalCVFromAddress, intCV, uintCV, PostConditionMode, stringAsciiCV, principalCV, standardPrincipalCV, FungibleConditionCode, makeContractCall, broadcastTransaction, READONLY_FUNCTION_CALL_PATH, cvToJSON, Pc } from '@stacks/transactions';
import { STACKS_MAINNET, STACKS_TESTNET } from '@stacks/network';

// nftAudioPlayer.js
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export const NFTAudioIntegration = {
    async fetchAlbumId() {
        try {
            // Get user address


            const options = {
                contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
                contractName: 'gated-005',
                functionName: 'get-album-id',
                functionArgs: [],
                network: STACKS_MAINNET,
                senderAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5'
            };

            // Add delay
            const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));
            await delay(200);

            const result = await fetchCallReadOnlyFunction(options);
            console.log('Album ID result:', result);

            if (result?.value) {
                // Handle bigint value by converting to number
                const albumId = Number(result.value); // This will convert 4n to 4
                // Or alternatively:
                // const albumId = parseInt(result.value.toString());

                console.log('Parsed Album ID:', albumId);
                return albumId;
            }

            return 0;
        } catch (error) {
            console.error('Error fetching album ID:', error);
            return 0;
        }
    },

    // Add track fetching
    async fetchTrackIds() {
        try {
            const userData = window.auth?.userSession?.loadUserData();
            const userAddressMainnet = userData?.profile?.stxAddress?.mainnet;

            console.log('Using address for tracks:', userAddressMainnet);

            // Helper function to fetch a single track
            const fetchTrackId = async (trackNumber) => {
                const options = {
                    contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
                    contractName: 'gated-005',
                    functionName: `get-track-${trackNumber}-id`,
                    functionArgs: [],
                    network: 'mainnet',
                    senderAddress: userAddressMainnet || 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5'
                };

                try {
                    const result = await fetchCallReadOnlyFunction(options);
                    return Number(result.value); // Convert BigInt to number
                } catch (error) {
                    console.error(`Error fetching track ${trackNumber}:`, error);
                    return 0;
                }
            };

            // Fetch all tracks with sequential delays
            const trackIds = [];
            for (let i = 1; i <= 8; i++) {
                // Add delay between calls
                if (i > 1) await new Promise(resolve => setTimeout(resolve, 200));

                console.log(`Fetching track ${i} ID...`);
                const trackId = await fetchTrackId(i);
                console.log(`Track ${i} ID:`, trackId);
                trackIds.push(trackId);
            }

            return trackIds;
        } catch (error) {
            console.error('Error fetching track IDs:', error);
            return Array(8).fill(0); // Return array of zeros on error
        }
    },

    async initialize() {
        console.log("NFT Integration initializing...");
        try {
            const albumId = await this.fetchAlbumId();
            console.log("Album ID:", albumId);
            // Rest of initialization if needed


            const trackIds = await this.fetchTrackIds();
            console.log("Track IDs:", trackIds);

            // Return combined data
            return {
                albumId,
                trackIds
            };
        } catch (error) {
            console.error('Failed to initialize:', error);
        }
    }
};
