import { AuthService } from '/auth-service.js';
import { UserSession } from '@stacks/auth';
import { AppConfig, showConnect, openContractCall } from '@stacks/connect';
import { STACKS_MAINNET, STACKS_TESTNET } from '@stacks/network';
import { bufferCV, boolCV, addressToString, Cl, deserializePrincipal, standardPrincipalCVFromAddress, intCV, uintCV, PostConditionMode, stringAsciiCV, principalCV, standardPrincipalCV, FungibleConditionCode, makeContractCall, broadcastTransaction, fetchCallReadOnlyFunction, READONLY_FUNCTION_CALL_PATH, cvToJSON, Pc } from '@stacks/transactions';
import WalletDropdown from './walletDropdown.js';
import gatedLogo from '/gated-logo2.png';
import blocktuneLogo from '/blocktune-logo.jpg';
import { NFTAudioIntegration } from './nftAvailability.js';
import loader from './loader.js';
import './nft-swap.js';

import roo from '/roo.png';
import { musicData, musicPlayer, albumMetadata } from './musicConfig.js';

import { ProfilePhoto, renderStoreItems, fetchUserNFTs, handleMint, createMusicPlayerCard, togglePlayPause, loadTrack, playPreviousTrack, playNextTrack } from './script.js';  // Add this import
import track1Image from './audio/Josie_image.png';
import track1Audio from './audio/Track 1_ Josie Field - Radio Silence.wav';
import track2Image from './audio/Ard_image.png';
import track2Audio from './audio/Track 2_ Ard Matthews - You Make it Alright.wav';
import track3Image from './audio/Arno_image.png';
import track3Audio from './audio/Track 3_ Arno Carstens - Mortals.wav';
import track4Image from './audio/Kahn_image.png';
import track4Audio from './audio/Track 4_ Kahn Morbee - Buzzhead.wav';
import track5Image from './audio/Laurie_image.png';
import track5Audio from './audio/Track 5_ Laurie Levine - Grey.wav';
import track6Image from './audio/Jacques_image2.png';
import track6Audio from '/audio/Track 6_ Jacques Moolman - Exile.wav';
import track7Image from './audio/Evert_Image.png';
import track7Audio from './audio/Track 7_ Evert Snyman - I Shouldnt Break.wav';
import track8Image from './audio/Xander_image.png';
import track8Audio from './audio/Track 8_ Gazelle - BBQ to Braai.wav';
import albumImage from '/audio/Album_image.png';


window.app = {
  showCreatePageModal: null,  // Initialize as null
  hideCreatePageModal: null   // Initialize as null
};

function sanitizeUrl(title) {
  return title
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/%20/g, '-') // Replace %20 with hyphens
    .replace(/[^a-z0-9-]/g, '') // Remove special characters
    .replace(/-+/g, '-') // Replace multiple hyphens with single hyphen
    .replace(/^-|-$/g, ''); // Remove leading/trailing hyphens

}



export class Router {


  constructor() {

    this.routes = [];
    this.currentStxAddress = null;
    this.mainContent = document.getElementById('main-content');

    // Bind the methods to this instance
    window.app.showCreatePageModal = () => this.showCreatePageModal();
    window.app.hideCreatePageModal = () => this.hideCreatePageModal();

    try {
      // Initialize auth first
      console.log('Creating AuthService');
      this.auth = new AuthService(this);


      // Then check for existing session
      this.checkExistingSession();

      console.log('AuthService created:', this.auth);
    } catch (error) {
      console.error('Failed to create AuthService:', error);
    }

    // Initialize route handlers immediately
    this.initializeRouteHandlers();

    // Initialize music player
    this.initializeMusicPlayer();

    // Wait for DOM to be fully loaded
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', () => this.init());
    } else {
      this.init();
    }

    console.log('AuthService created:', this.auth);

    // Handle route changes
    window.addEventListener('hashchange', () => this.handleRoute());
    window.addEventListener('popstate', () => this.handleRoute());

    // Setup global wallet functions
    window.connectWallet = async (isChange = false) => {
      try {
        await this.auth.connectWallet(isChange);
        this.handleRoute();
      } catch (error) {
        console.error('Failed to connect wallet:', error);
      }
    };

    window.disconnectWallet = () => {
      this.auth.disconnectWallet();
    };
    console.log('Router constructor completed');

  }

  createPageObject(pageData, tokenId, userAddressMainnet, blockHeight) {
    const isActive = typeof pageData.active === 'boolean'
      ? pageData.active
      : pageData.active?.type === 'true' ||
      pageData.active?.value === true ||
      pageData.active?.type === 3;

    return {
      type: 'page',
      id: tokenId,
      owner: pageData.owner.value,
      active: isActive,
      title: pageData.title.value,
      description: pageData.description.value,
      metadataUri: pageData['metadata-uri']?.value.data || '',
      url: `https://${userAddressMainnet}.gated.so/#/${pageData.title?.data || tokenId}`,
      blockHeight: blockHeight
    };
  }
  setupMusicEventListeners() {
    setTimeout(() => {
      const songItems = document.querySelectorAll('.song-list li');
      const playPauseBtn = document.getElementById('play-pause-btn');
      const prevBtn = document.getElementById('prev-btn');
      const nextBtn = document.getElementById('next-btn');
      const cardHeader = document.querySelector('.card-header');

      // Use window.musicPlayer instead of creating new audio
      if (!window.musicPlayer) {
        console.error('Music player not initialized');
        return;
      }

      songItems.forEach((item, index) => {
        item.addEventListener('click', (e) => {
          if (e.target.classList.contains('mint-track-btn')) return;

          // Use the main player methods
          window.musicPlayer.loadTrack(index, musicData);
          window.musicPlayer.togglePlayPause();

          songItems.forEach(i => i.classList.remove('active-track'));
          item.classList.add('active-track');

          if (cardHeader) {
            cardHeader.style.backgroundImage = `url(${musicData.songs[index].imgUrl})`;
          }
        });
      });

      // Use main player methods for buttons
      if (playPauseBtn) {
        playPauseBtn.addEventListener('click', () => window.musicPlayer.togglePlayPause());
      }

      if (prevBtn) {
        prevBtn.addEventListener('click', () => window.musicPlayer.previousTrack());
      }

      if (nextBtn) {
        nextBtn.addEventListener('click', () => window.musicPlayer.nextTrack());
      }

      // Add progress bar update
      window.musicPlayer.audioPlayer.addEventListener('timeupdate', () => {
        const progressBar = document.getElementById('progress-bar');
        if (progressBar && !isNaN(window.musicPlayer.audioPlayer.duration)) {
          const percentage = (window.musicPlayer.audioPlayer.currentTime / window.musicPlayer.audioPlayer.duration) * 100;
          progressBar.value = percentage;
        }
      });
    }, 100);
  }

  getCurrentStxAddress() {
    if (!this.auth) {
      console.error('Auth not initialized');
      return null;
    }
    if (!this.auth.isSignedIn()) {
      console.error('User not signed in');
      return null;
    }
    return this.auth.getCurrentAddress();
  }

  init() {
    // First check if element exists
    this.mainContent = document.getElementById('main-container');

    // If it doesn't exist, create it
    if (!this.mainContent) {
      this.mainContent = document.createElement('div');
      this.mainContent.id = 'main-container';
      document.body.appendChild(this.mainContent);
    }

    // Now handle the initial route
    this.handleRoute();
  }

  async findPageTransaction(cleanAddress, decodedTitle) {
    const limit = 50;
    let offset = 0;

    while (true) {
      console.log(`Searching transactions with offset: ${offset}`);
      const txResponse = await fetch(
        `https://stacks-node-api.mainnet.stacks.co/extended/v1/address/${cleanAddress}/transactions?limit=${limit}&offset=${offset}`
      );
      const txData = await txResponse.json();

      if (!txData.results || txData.results.length === 0) {
        console.log('No more transactions to search');
        break;
      }

      const pageTx = txData.results.find(tx => {
        const titleArg = tx.contract_call?.function_args?.[0]?.repr;


        // Remove quotes from blockchain title
        const cleanTitleArg = titleArg?.replace(/^"|"$/g, '');

        console.log('Comparing:', {
          cleanTitleArg,
          decodedTitle,
          matches: cleanTitleArg === decodedTitle
        });

        const normalizedTitleArg = titleArg?.toLowerCase().replace(/["\s-]/g, '');
        const normalizedSearchTitle = decodedTitle.toLowerCase().replace(/["\s-]/g, '');

        console.log('normalizedTitleArg', normalizedTitleArg)
        console.log('normalizedSearchTitle', normalizedTitleArg)

        return tx.contract_call?.function_name === 'add-page' &&
          normalizedTitleArg === normalizedSearchTitle;
      });

      if (pageTx) {
        console.log('Found matching transaction at offset:', offset);
        return pageTx;
      }

      // If we got less than the limit, we've reached the end
      if (txData.results.length < limit) {
        console.log('Reached end of transactions');
        break;
      }

      offset += limit;
    }

    return null;
  }

  initializeRouteHandlers() {
    this.routeHandlers = {
      home: {
        match: (parsedUrl) => {
          const matches = !parsedUrl.stxAddress && !parsedUrl.pageTitle;
          console.log('Home match check:', {
            hasStxAddress: !!parsedUrl.stxAddress,
            hasPageTitle: !!parsedUrl.pageTitle,
            matches
          });
          return matches;
        },
        render: () => this.renderHomePage()
      },
      dashboard: {
        match: (parsedUrl) => {
          const stxMatches = parsedUrl.stxAddress?.toLowerCase() === this.currentStxAddress?.toLowerCase();
          const noPageTitle = !parsedUrl.pageTitle;
          console.log('Dashboard match check:', {
            stxMatches,
            noPageTitle,
            currentStx: this.currentStxAddress,
            parsedStx: parsedUrl.stxAddress
          });
          return stxMatches && noPageTitle;
        },
        render: (stxAddress) => this.renderAdminDashboard(stxAddress)
      },
      claim: {
        match: (parsedUrl) => {
          const stxMatches = parsedUrl.stxAddress?.toLowerCase() !== this.currentStxAddress?.toLowerCase();
          const noPageTitle = !parsedUrl.pageTitle;
          console.log('Claim match check:', {
            stxMatches,
            noPageTitle,
            currentStx: this.currentStxAddress,
            parsedStx: parsedUrl.stxAddress
          });
          return parsedUrl.stxAddress && noPageTitle && stxMatches;
        },
        render: (stxAddress) => this.renderClaimPage(stxAddress)
      },
      specificPage: {
        match: (parsedUrl) => {
          const isSpecificPage = parsedUrl.pageTitle?.toLowerCase() === 'when-walls-break';
          const allowedHosts = [
            'spmymhhc95ye7ezef19nnwszmhxej97zyhjb5nxv.localhost',
            'sp3v0kzbgk20cmee74ky0j0h0mhysgwvarekwmcpq.localhost',
            'sp3v0kzbgk20cmee74ky0j0h0mhysgwvarekwmcpq.gated.so',
            'blocktune.gated.so'
          ];
          const currentHost = window.location.hostname;
          const isAllowedHost = allowedHosts.includes(currentHost);

          return isSpecificPage && isAllowedHost;
        },
        render: async (stxAddress, pageTitle) => {
          // Show loader immediately
          loader.show();

          try {
            // Update loader text for different stages
            loader.updateText('Loading album metadata...');
            console.log("Album Metadata:", JSON.stringify(albumMetadata, null, 2));

            loader.updateText('Initializing music player...');
            // With this:
            if (!window.musicPlayer?.isInitialized) {
              // Create a proper instance of the music player
              window.musicPlayer = Object.create(musicPlayer);

              // Initialize audio player first
              window.musicPlayer.audioPlayer = new Audio();  // Add this line

              // Handle both preview time limit and track ending
              window.musicPlayer.audioPlayer.addEventListener('timeupdate', () => {
                if (window.musicPlayer.isPreviewMode &&
                  window.musicPlayer.audioPlayer.currentTime >= window.musicPlayer.MAX_PLAY_TIME) {
                  // When preview time limit is reached, move to next track
                  const nextIndex = (window.musicPlayer.currentTrackIndex + 1) % musicData.songs.length;
                  window.musicPlayer.setCurrentTrack(nextIndex);
                  window.musicPlayer.loadTrack(nextIndex, musicData);

                  // Auto-play next track
                  const playPromise = window.musicPlayer.audioPlayer.play();
                  if (playPromise) {
                    playPromise
                      .then(() => {
                        window.musicPlayer.startAudioTimer();
                        window.musicPlayer.startCountdown();
                        window.musicPlayer.updatePlayPauseButtons(true);
                      })
                      .catch(error => {
                        console.warn('Auto-play prevented:', error);
                        window.musicPlayer.updatePlayPauseButtons(false);
                      });
                  }
                }
              });

              // Add auto-play event listener before initialization
              window.musicPlayer.audioPlayer.addEventListener('ended', () => {
                const nextIndex = (window.musicPlayer.currentTrackIndex + 1) % musicData.songs.length;
                window.musicPlayer.setCurrentTrack(nextIndex);
                window.musicPlayer.loadTrack(nextIndex, musicData);

                // Auto-play with error handling
                const playPromise = window.musicPlayer.audioPlayer.play();
                if (playPromise) {
                  playPromise
                    .then(() => {
                      if (window.musicPlayer.isPreviewMode) {
                        window.musicPlayer.startAudioTimer();
                        window.musicPlayer.startCountdown();
                      }
                      window.musicPlayer.updatePlayPauseButtons(true);
                    })
                    .catch(error => {
                      console.warn('Auto-play prevented:', error);
                      window.musicPlayer.updatePlayPauseButtons(false);
                    });
                }
              });

              // Initialize after adding event listener
              window.musicPlayer.init();
            }

            loader.updateText('Preparing content...');
            this.mainContent.innerHTML = `
              <div class="profile-section">
              <div id="profileOuterContainer" class="profile-outer-container">
              <div id="profileInnerContainer" class="profile-inner-container">
              <div id="profilePhoto" class="profile-photo">

                <img src=${blocktuneLogo} alt="Gated Logo" class="logo" style="width:150px;" />
                </div>
                <div id="tipText" class="tip-text">Tip 1 STX</div>

              </div>
        </div>
                <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
      <div id="bnsv2NameDisplay" class="bns-name">
        <a href="https://x.com/thiskittyisgood" target="_blank">@goodkitty</a>
      </div>
    </div>
    <div class="page-wrapper">
        <div class="page-header">
        </div>
        <div class="page-description">
          <i>When Walls Break</i> features 8 original tracks
          <br>paired with 8 unique artworks inspired by the music.
          <br>This groundbreaking album redefines music ownership
          <br>with only 100 collector editions available.
          <br>Release date 19 Nov 2024.
        </div>
        <div id="store-container"></div>
        </div>
        <img src=${gatedLogo} alt="Gated Logo" class="logo" id="logoButton" style="width:100px;padding:40px;cursor:pointer;"/>
`;

            // Add your existing style additions
            const style = document.createElement('style');
            style.textContent = `
        .profile-outer-container {
          transform-origin: center;
          transition: transform 0.1s ease;
        }
      `;
            document.head.appendChild(style);

            loader.updateText('Loading When Walls Break...');
            const nftIntegration = {
              ...NFTAudioIntegration,
              auth: window.auth
            };

            const nftAvailability = await nftIntegration.initialize();
            console.log('NFT Availability:', nftAvailability);

            setTimeout(() => {
              try {
                if (!window.profilePhoto) {
                  window.profilePhoto = new ProfilePhoto();
                }
              } catch (error) {
                console.error('Error rendering specific page:', error);
              }
            }, 0);

            renderStoreItems([musicData], nftAvailability);
            this.setupMusicEventListeners();

            // Hide loader when everything is ready
            loader.hide();

          } catch (error) {
            console.error('Error rendering specific page:', error);
            this.mainContent.innerHTML = `
        <div class="error-message">
          Failed to load content. Please try again later.
        </div>
      `;
            loader.hide();
          }
        }
      },
      page: {
        match: (parsedUrl) => {
          console.log('Checking page match:', parsedUrl);
          return parsedUrl.stxAddress &&
            parsedUrl.hasPageTitle &&
            parsedUrl.pageTitle !== 'riseofthememe' &&
            parsedUrl.pageTitle !== 'when-walls-break';
        },
        render: async (stxAddress, pageTitle) => {
          loader.show();
          try {
            const cleanAddress = stxAddress.toUpperCase();
            const decodedTitle = decodeURIComponent(pageTitle).replace(/-/g, ' ');

            const capitalizedTitle = decodedTitle
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
              .join(' ');

            // Find the transaction
            const pageTx = await this.findPageTransaction(cleanAddress, decodedTitle);


            if (pageTx) {
              console.log('Found transaction details:', {
                txId: pageTx.tx_id,
                functionName: pageTx.contract_call?.function_name,
                title: pageTx.contract_call?.function_args?.[0]?.repr,
                description: pageTx.contract_call?.function_args?.[1]?.repr,
                timestamp: pageTx.burn_block_time,
                status: pageTx.tx_status
              });

              // Clean up existing instance
              if (window.profilePhoto) {
                window.profilePhoto.cleanup(); // Add a cleanup method to ProfilePhoto class
                window.profilePhoto = null;
              }

              this.mainContent.innerHTML = `
            <div class="profile-section">
              <div class="profile-top-container">
                <div id="profileOuterContainer" class="profile-outer-container">
                  <div id="profileInnerContainer" class="profile-inner-container">
                    <div id="profilePhoto" class="profile-photo">
                      <img src="" alt="" class="logo" />
                    </div>
                    <div id="tipText" class="tip-text">Tip 1 STX</div>
                  </div>
                </div>
                <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
                <div id="bnsv2NameDisplay" class="bns-name">${decodedTitle}</div>
                <div class="page-description">${pageTx.contract_call?.function_args?.[1]?.repr.replace(/^"|"$/g, '') || 'No description available'}</div>
              </div>
              
              ${pageTx ? `
                <div class="ownership-section">
                  <button onclick="window.open('https://explorer.stacks.co/txid/${pageTx.tx_id}', '_blank')" class="verify-button">
                    <span class="verify-icon">✓</span>
                      Owned by ${cleanAddress.slice(0, 4)}...${cleanAddress.slice(-4)}
                  </button>
                </div>
              ` : ''}
            </div>
                `;

              // Add your styles
              const style = document.createElement('style');
              style.textContent = `
        .profile-section {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 2rem;
        }

        .profile-outer-container {
          position: relative;
          transform-origin: center;
          transition: transform 0.1s ease;
        }

        .profile-inner-container {
          position: relative;
          cursor: pointer;
        }

        .profile-photo {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          overflow: hidden;
          background: #000;
          display: flex;
          align-items: center;
          justify-content: center;
        }

   

        .tip-text {
          position: absolute;
          bottom: -30px;
          left: 50%;
          transform: translateX(-50%);
          opacity: 0;
          transition: opacity 0.3s ease;
          white-space: nowrap;
          color: #000;
        }
      `;
              document.head.appendChild(style);

              // Wait for a brief moment to ensure DOM is ready
              await new Promise(resolve => setTimeout(resolve, 100));

              // Verify elements exist before initialization
              const requiredElements = ['profileOuterContainer', 'profileInnerContainer', 'profilePhoto', 'tipText', 'clickSound'];

              // Check if all elements exist
              const allElementsExist = requiredElements.every(id => {
                const element = document.getElementById(id);
                if (!element) {
                  console.error(`Element ${id} not found`);
                  return false;
                }
                return true;
              });

              if (allElementsExist) {
                // Only initialize if all elements exist
                if (!window.profilePhoto) {
                  window.profilePhoto = new ProfilePhoto();
                  console.log('ProfilePhoto initialized successfully');
                }
              } else {
                console.error('Some required elements are missing');
              }

              loader.hide();
            } else {
              loader.hide()
              console.log('No matching transaction found - showing unclaimed page');
              this.mainContent.innerHTML = `
          <div class="unclaimed-container">
            <h1>${decodedTitle}</h1>
            <p>This page belongs to ${cleanAddress}</p>
          
          </div>
        `;
              console.log('No matching transaction found');
            }
          } catch (error) {
            console.error('Error in page render:', error);
            this.mainContent.innerHTML = `
        <div class="error-message">
          Failed to load content. Please try again later.
        </div>
      `;
            loader.hide();
            // Use the transaction data
            // ... rest of your code



          }
        }
      },
      riseofthememe: {
        match: (parsedUrl) => {
          return parsedUrl.pageTitle === 'riseofthememe';
        },
        render: async (stxAddress, pageTitle) => {
          loader.show();

          try {
            loader.updateText('Loading Rise of the Meme...');

            // First set the base HTML structure
            this.mainContent.innerHTML = `
        <div id="page-container">
          <div class="profile-section">
            <div id="profileOuterContainer" class="profile-outer-container">
              <div id="profileInnerContainer" class="profile-inner-container">
                <div id="profilePhoto" class="profile-photo risememe-photo">
                  <img src="https://images.gamma.io/cdn-cgi/image/quality=100,width=300,height=300/https://images.gamma.io/ipfs/QmXFcWnqAiCnkx23riPZH8Tm6YX3MQkB9yeMNQ8tQENGoj" alt="Profile" class="logo" style="width:150px;" />
                </div>
                <div id="tipText" class="tip-text">Tip 1 STX</div>
              </div>
            </div>
            <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
            <div id="bnsv2NameDisplay" class="bns-name">
              <a href="https://x.com/RiseMemeTribes" target="_blank">@RiseMemeTribes</a>
            </div>
          </div>

          <div class="page-wrapper">
            <div class="page-header"></div>
            <div class="page-description">
              <i>Rise of the Meme</i> is a revolutionary NFT collection celebrating internet culture.
              <br/>Each piece captures iconic meme moments in digital history.
              <br/>Limited to 100 editions per collection.
              <br/>Launch date: December 2024
            </div>
            <div id="nft-swap-container" class="p-6 max-w-2xl mx-auto">
              <!-- Add a placeholder text to verify the container exists -->
              Loading NFT Swap...
            </div>
          </div>
          <div class="logo-container">
          <img src="${gatedLogo}" alt="Gated Logo" class="logo" id="logoButton" style="width:100px;padding:40px;cursor:pointer;"/>
          </div>
        </div>

       
      `;

            // Initialize NFT display
            const displayNFTs = async () => {
              try {
                const nftContainer = document.getElementById('nft-container');
                if (!nftContainer) return;

                // Get NFT details using the provided address
                const nftDetails = await getNFTDetails(stxAddress);

                if (nftDetails.success && nftDetails.nfts.length > 0) {
                  const nftsWithMetadata = await Promise.all(nftDetails.nfts.map(async (nft) => {
                    const metadata = await fetchNFTMetadata(nft.ipfsUrl);
                    const imageUrl = metadata?.image ?
                      metadata.image.replace('ipfs://ipfs/', 'https://ipfs.io/ipfs/') :
                      null;

                    return {
                      ...nft,
                      name: metadata?.name || `Rise of the Meme #${nft.tokenId}`,
                      imageUrl: imageUrl
                    };
                  }));

                  // Create HTML for each NFT
                  nftsWithMetadata.forEach(nft => {
                    const nftCard = document.createElement('div');
                    nftCard.className = 'bg-white rounded-lg shadow-md p-4';
                    nftCard.innerHTML = `
                                <h3 class="text-lg font-semibold mb-2">${nft.name}</h3>
                                ${nft.imageUrl ?
                        `<img src="${nft.imageUrl}" alt="${nft.name}" class="w-full h-auto rounded-md mb-2">` :
                        '<div class="bg-gray-200 w-full h-48 rounded-md mb-2"></div>'
                      }
                                <p class="text-sm text-gray-600">Token ID: ${nft.tokenId}</p>
                            `;
                    nftContainer.appendChild(nftCard);
                  });
                } else {
                  nftContainer.innerHTML = '<p class="text-gray-600">No Rise of the Meme NFTs found in your wallet.</p>';
                }
              } catch (error) {
                console.error('Error displaying NFTs:', error);
                nftContainer.innerHTML = '<p class="text-red-600">Error loading NFTs. Please try again later.</p>';
              }
            };

            // Wait for DOM to be ready
            await new Promise(resolve => setTimeout(resolve, 100));

            // Initialize profile photo only after DOM is ready and user session exists
            const initializeProfile = async () => {
              try {
                // Make sure all required elements exist
                const requiredElements = [
                  'profileOuterContainer',
                  'profileInnerContainer',
                  'profilePhoto',
                  'tipText',
                  'clickSound'
                ];

                const missingElements = requiredElements.filter(id => !document.getElementById(id));
                if (missingElements.length > 0) {
                  console.error('Missing elements:', missingElements);
                  return;
                }

                // Initialize profile photo with proper user session check
                if (!window.profilePhoto && window.userSession) {
                  window.profilePhoto = new ProfilePhoto(window.userSession);
                }
              } catch (error) {
                console.error('Error initializing profile:', error);
              }
            };

            // Initialize the NFT swap component
            const nftSwapContainer = document.getElementById('nft-swap-container');
            if (nftSwapContainer && window.NFTSwapModule) {
              console.log("Initializing NFT swap component");
              const router = this; // 'this' is the router instance

              console.log("Router state:", {
                hasAuth: !!router.auth,
                isSignedIn: router.auth?.isSignedIn()
              });

              // Only initialize if auth is ready
              if (!router.auth) {
                console.error('Auth not initialized in router');
                return;
              }

              window.NFTSwapModule.init(nftSwapContainer, router);
            } else {
              console.error("Could not initialize NFT swap: ", {
                containerExists: !!nftSwapContainer,
                moduleExists: !!window.NFTSwapModule
              });
            }




            // Call initialization after a slight delay to ensure DOM is ready
            // Initialize everything
            setTimeout(() => {
              initializeProfile();
              displayNFTs();
            }, 200);

            loader.hide();

          } catch (error) {
            console.error('Error rendering Rise of the Meme page:', error);
            this.mainContent.innerHTML = `
        <div class="error-message">
          Failed to load content. Please try again later.
        </div>
      `;
            loader.hide();
          }
        }
      }

    }
  }


  checkExistingSession() {
    if (this.auth.userSession.isUserSignedIn()) {
      const userData = this.auth.userSession.loadUserData();
      const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
      this.currentStxAddress = userData.profile.stxAddress[network];
    }
  }

  initializeMusicPlayer() {
    console.log('Router: Starting music player initialization with auth:', this.auth);

    if (!window.musicPlayer) {
      window.musicPlayer = { ...musicPlayer };
      window.musicPlayer.auth = this.auth;  // Set auth on window.musicPlayer

      if (!window.musicPlayer.isInitialized) {
        window.musicPlayer.init();
      }
    }

    // Ensure auth is set even if musicPlayer already exists
    window.musicPlayer.auth = this.auth;
  }

  async handleRoute() {
    if (!this.mainContent) {
      console.error('Main content element not initialized!');
      return;
    }

    this.mainContent.innerHTML = '';

    try {
      const parsedUrl = this.parseUrl();
      console.log('Starting route handling with:', parsedUrl);

      // Dashboard check
      if (parsedUrl.stxAddress && this.auth?.isSignedIn()) {
        const currentAddress = this.auth.getCurrentAddress()?.toLowerCase();
        const parsedAddress = parsedUrl.stxAddress?.toLowerCase();

        console.log('Dashboard auth check:', {
          currentAddress,
          parsedAddress,
          isSignedIn: this.auth.isSignedIn()
        });

        if (currentAddress === parsedAddress && !parsedUrl.pageTitle) {
          await this.renderAdminDashboard(parsedUrl.stxAddress);
          return;
        }
      }

      // Route matching with detailed logging
      console.log('Checking routes for:', parsedUrl);
      const matchingRoute = Object.entries(this.routeHandlers)
        .find(([name, handler]) => {
          console.log(`Checking route '${name}'...`);
          if (!handler || typeof handler.match !== 'function') {
            console.error(`Invalid handler for route '${name}'`);
            return false;
          }
          const matches = handler.match(parsedUrl);
          console.log(`Route '${name}' match result:`, matches);
          return Boolean(matches);
        });

      if (matchingRoute) {
        console.log('Rendering route:', matchingRoute[0]);
        await matchingRoute[1].render(parsedUrl.stxAddress, parsedUrl.pageTitle);
        return;
      }

      console.log('No matching route found for:', parsedUrl);
      this.renderErrorPage(new Error(`No page exists at this URL: ${window.location.pathname}${window.location.hash}`));
    } catch (error) {
      console.error('Error handling route:', error);
      this.renderErrorPage(error);
    }
  }

  parseUrl() {
    const host = window.location.hostname;
    const hash = window.location.hash.slice(1) || '/';
    const pathname = window.location.pathname;

    console.log('Parsing URL:', {
      host,
      hash,
      pathname,
      fullUrl: window.location.href
    });

    // Handle auth redirect first
    if (pathname === '/auth') {
      if (this.auth?.userSession.isSignInPending()) {
        this.auth.handlePendingSignIn();
      }
      window.location.href = '/';
      return { stxAddress: null, pageTitle: null };
    }

    // Extract STX address from subdomain
    const stxAddressMatch = host.match(/^([a-zA-Z0-9]+)\.(localhost|gated\.so)$/);
    const stxAddress = stxAddressMatch ? stxAddressMatch[1] : null;

    // Improve page title parsing
    const hasPageTitle = hash.startsWith('/');
    const rawPageTitle = hasPageTitle ? hash.slice(1) : hash;
    const pageTitle = rawPageTitle ? decodeURIComponent(rawPageTitle) : null;

    const parsed = {
      stxAddress,
      pageTitle,
      hasPageTitle,  // Add this flag
      isProduction: host.includes('gated.so')
    };

    console.log('Parsed URL result:', parsed);  // Add this debug log
    return parsed;
  }

  renderErrorPage(error) {
    console.error('Rendering error page:', error);

    this.mainContent.innerHTML = `
        <div class="error-container">
            <div class="error-content">
                <h1>Oops! Something went wrong</h1>
                <p class="error-message">${this.escapeHtml(error.message || 'An unexpected error occurred')}</p>
                <div class="error-actions">
                    <button onclick="window.location.reload()" class="retry-btn">
                        Try Again
                    </button>
                    <a href="/" class="home-btn">
                        Go to Home
                    </a>
                </div>
            </div>
        </div>
    `;
  }

  setCurrentStxAddress(address) {
    this.currentStxAddress = address;
    if (this.mainContent) {
      this.handleRoute();
    }
  }

  navigate(path, stxAddress = null) {
    const baseUrl = stxAddress
      ? `${window.location.protocol}//${stxAddress}.${window.location.host}`
      : window.location.origin;

    const newUrl = `${baseUrl}${path}`;
    window.history.pushState({}, '', newUrl);
    this.handleRoute();
  }

  navigateToAdmin(stxAddress) {
    // Get current user data to check network
    const userData = this.auth.userSession.loadUserData();
    const connectedAddress = userData.profile.stxAddress;


    // Determine if we're connected to testnet or mainnet based on which address matches
    const isTestnet = connectedAddress.testnet.toLowerCase() === stxAddress.toLowerCase();

    console.log('Navigation details:', {
      stxAddress,
      connectedTestnet: connectedAddress.testnet,
      connectedMainnet: connectedAddress.mainnet,
      isTestnet
    });

    // Set domain based on network
    const domain = isTestnet ? 'localhost:3000' : 'gated.so';

    // Construct the full URL
    const targetUrl = `${window.location.protocol}//${stxAddress}.${domain}`;

    console.log('Navigating to:', targetUrl);

    // Perform the navigation
    window.location.href = targetUrl;
  }

  getPageUrl(stxAddress, pageTitle) {
    const domain = window.location.hostname.includes('gated.so') ? 'gated.so' : 'localhost';
    return `${window.location.protocol}//${stxAddress}.${domain}/#/${pageTitle}`;
  }

  // You might also want to update the getDashboardUrl method to match:
  getDashboardUrl(stxAddress) {
    // Get current user data
    const userData = this.auth.userSession.loadUserData();
    const connectedAddress = userData.profile.stxAddress;

    // Check if we're on testnet or mainnet by comparing addresses
    const isTestnet = connectedAddress.testnet.toLowerCase() === stxAddress.toLowerCase();

    // Set appropriate domain
    const domain = isTestnet ? 'localhost:3000' : 'gated.so';

    console.log('Dashboard URL generation:', {
      stxAddress,
      testnetAddress: connectedAddress.testnet,
      mainnetAddress: connectedAddress.mainnet,
      isTestnet,
      domain
    });

    // Return the full URL
    return `${window.location.protocol}//${stxAddress}.${domain}`;
  }

  renderHomePage() {
    console.log('Starting renderHomePage');
    console.log('Auth object:', this.auth);

    const isSignedIn = this.auth.isSignedIn();
    const currentAddress = this.auth.getCurrentAddress();

    this.mainContent.innerHTML = `
        <div class="home-page">
            <img src=${gatedLogo} alt="Gated Logo" class="logo" style="width:400px;padding:40px;cursor:auto;"/>
            ${isSignedIn ? `
                <div id="wallet-container">
                    <div class="dropdown">
                        <div class="button-group">
                            <button 
                                onclick="window.router.navigateToAdmin('${currentAddress}')" 
                                class="dashboard-btn"
                            >
                                Go to Dashboard
                            </button>
                            <button 
                                id="wallet-dropdown-btn" 
                                class="dropdown-toggle-btn"
                                aria-label="Toggle menu"
                            >
                                <svg class="chevron-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                    <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                            </button>
                        </div>
                        <div id="wallet-dropdown-content" class="dropdown-content">
                            <a class="dropdown-item" onclick="window.disconnectWallet()">Disconnect</a>
                        </div>
                    </div>
                </div>
            ` : `
                <button id="connectWallet-button" onclick="window.connectWallet()" class="connect-btn">
                    Connect Wallet
                </button>
            `}
        </div>
    `;

    // Initialize and render the wallet dropdown
    if (isSignedIn) {
      this.setupWalletDropdown();
    }
  }

  async loadPages(stxAddress) {
    const pagesList = document.getElementById('pages-list');
    pagesList.innerHTML = '<div class="loading">Searching blockchain for pages you own...</div>';

    try {
      const { pages } = await this.fetchNFTPages();  // Destructure pages from the result

      if (!pages || pages.length === 0) {
        pagesList.innerHTML = `
        <div class="empty-state">
          <p>No pages found. Create your first page!</p>
        </div>
      `;
        return;
      }



      pagesList.innerHTML = pages.map(page => `
      <div class="page-card">
        <div class="page-info">
          <h3>${page.title || `Page #${page.id}`}</h3>
          <p class="dashboard-page-description">${page.description || 'No description available'}</p>
          <div class="page-metadata">
            <span class="status">${page.active ? 'Active' : 'Inactive'}</span>
          </div>
        </div>
        <div class="page-actions">
          <a href="#/${sanitizeUrl(page.title)}" target="_blank" class="view-btn">
            View Page
          </a>

          <button onclick="window.router.togglePageStatus(${page.id}, ${page.active})"
                  class="status-btn ${page.active ? 'active' : 'inactive'}">
            ${page.active ? 'Unpublish' : 'Publish'}
          </button>
        </div>
      </div>
    `).join('');

    } catch (error) {
      console.error('Error loading pages:', error);
      pagesList.innerHTML = `
      <div class="error-state">
        <p>Error loading your pages. Please try again.</p>
        <button onclick="window.router.loadPages('${stxAddress}')" class="retry-btn">
          Retry
        </button>
      </div>
    `;
    }
  }

  async togglePageStatus(id, currentStatus) {
    const button = event.currentTarget;
    button.disabled = true;

    try {
      await this.updatePage(id, currentStatus);
    } catch (error) {
      console.error('Toggle failed:', error);
      // Reset button state
      button.textContent = currentStatus ? 'Unpublish' : 'Publish';
      button.classList.toggle('active', currentStatus);
    } finally {
      button.disabled = false;
    }
  }

  async fetchNFTPages() {
    try {
      const userData = this.auth.userSession.loadUserData();
      const userAddressMainnet = userData.profile.stxAddress.mainnet;
      console.log('Fetching NFTs for mainnet address:', userAddressMainnet);

      const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddressMainnet}&limit=50`;

      const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      const holdingsResponse = await fetch(nftHoldingsUrl);
      if (!holdingsResponse.ok) return { pages: [], nfts: [] };

      const holdingsData = await holdingsResponse.json();
      if (!holdingsData.results?.length) return { pages: [], nfts: [] };

      const sortedNFTs = holdingsData.results.sort((a, b) => b.block_height - a.block_height);
      console.log(`Found ${sortedNFTs.length} NFTs total`);

      const processedContent = [];
      for (const nft of sortedNFTs) {
        const [contractAddress, assetName] = nft.asset_identifier.split('::');
        const valueStr = nft.value?.repr || '';
        console.log('Processing NFT:', { assetName, valueStr });

        // Check if this is from gated-pages-001 contract
        if (nft.asset_identifier.includes('gated-pages-001')) {
          console.log('Found gated-pages-001 NFT:', nft);
          try {

            const title = nft.value?.repr.replace(/^"|"$/g, '').replace(/\\"/g, '"');
            console.log("title for function call", title)

            const options = {
              contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
              contractName: 'gated-pages-001',
              functionName: 'get-page',
              functionArgs: [
                principalCV(userAddressMainnet),
                stringAsciiCV(title)
              ],
              network: STACKS_MAINNET,
              senderAddress: userAddressMainnet
            };

            await delay(200);
            const result = await fetchCallReadOnlyFunction(options);

            if (result?.value?.value) {
              const pageData = result.value.value;
              const page = {
                type: 'page',
                id: valueStr,
                owner: pageData.owner.value,
                active: pageData.active?.value ?? true,
                title: pageData.title.value,
                description: pageData.description.value,
                metadataUri: pageData['metadata-uri']?.value?.data || '',
                url: `https://${userAddressMainnet}.gated.so/#/${valueStr}`,
                blockHeight: nft.block_height,
                contractType: 'gated-pages-001'
              };

              console.log('Processed gated-pages-001 page:', page);
              processedContent.push(page);
            }
          } catch (error) {
            console.error('Error processing gated-pages-001:', error);
          }
        }

        // Handle gated-001 contract
        if (assetName === 'gated-page' && !nft.asset_identifier.includes('gated-pages-001')) {
          const tokenId = nft.value?.repr ? parseInt(nft.value.repr.replace(/^u/, '')) : null;
          if (!tokenId) continue;

          try {
            const options = {
              contractAddress: 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5',
              contractName: 'gated-001',
              functionName: 'get-page',
              functionArgs: [uintCV(tokenId)],
              network: STACKS_MAINNET,
              senderAddress: userAddressMainnet
            };

            await delay(200);
            const result = await fetchCallReadOnlyFunction(options);

            if (result?.value?.value) {
              const pageData = result.value.value;
              const page = {
                type: 'page',
                id: tokenId,
                owner: pageData.owner.value,
                active: pageData.active?.value ?? true,
                title: pageData.title.value,
                description: pageData.description.value,
                metadataUri: pageData['metadata-uri']?.value?.data || '',
                url: `https://${userAddressMainnet}.gated.so/#/${pageData.title?.data || tokenId}`,
                blockHeight: nft.block_height,
                contractType: 'gated-001'
              };

              console.log('Processed gated-001 page:', page);
              processedContent.push(page);
            }
          } catch (error) {
            console.error('Error processing gated-001:', error);
          }
        }
      }

      this.pages = processedContent.filter(item => item && item.type === 'page');
      console.log('Final pages:', this.pages);
      return { pages: this.pages, nfts: [] };

    } catch (error) {
      console.error('Error in fetchUserContent Mainnet:', error);
      return { pages: [], nfts: [] };
    }
  }

  // Helper method to format addresses
  formatAddress(address) {
    if (!address) return '';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  }

  // Add these navigation methods
  viewNFTPage(contractAddress, tokenId) {
    // Implement view page navigation
    window.open(`/${contractAddress}/${tokenId}`, '_blank');
  }

  editNFTPage(contractAddress, tokenId) {
    // Implement edit page navigation
    window.location.href = `/edit/${contractAddress}/${tokenId}`;
  }



  renderClaimPage(stxAddress) {
    // First check if session is still valid
    if (!this.auth.userSession.isUserSignedIn() && this.auth.isSignedIn()) {
      console.log('Session expired, triggering sign out');
      this.auth.handleSignOut();
      return;
    }

    const isSignedIn = this.auth.userSession.isUserSignedIn();
    let currentAddress = null;

    if (isSignedIn) {
      const userData = this.auth.userSession.loadUserData();
      // Get address based on network (testnet or mainnet)
      const network = window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet';
      currentAddress = userData.profile.stxAddress[network];
    }

    // Normalize addresses to lowercase for comparison
    const normalizedPageAddress = stxAddress?.toLowerCase();
    const normalizedCurrentAddress = currentAddress?.toLowerCase();
    const matchesPage = normalizedCurrentAddress === normalizedPageAddress;

    console.log('Claim page state:', {
      isSignedIn,
      currentAddress,
      stxAddress,
      normalizedCurrentAddress,
      normalizedPageAddress,
      matchesPage,
      network: window.location.hostname.includes('gated.so') ? 'mainnet' : 'testnet'
    });

    let contentToShow;

    if (!isSignedIn) {
      contentToShow = `
            <div class="connect-prompt">
                <p>Connect your wallet to claim this page</p>
                <button id="connectWallet-button" onclick="window.connectWallet()">
                    Connect Wallet
                </button>
            </div>
        `;
    } else if (matchesPage) {
      const dashboardUrl = this.getDashboardUrl(stxAddress);
      contentToShow = `
    <div class="access-message">
     <p>✅ Access Granted</p>
      <button 
        onclick="showSuccessAndRedirect('${dashboardUrl}')" 
        class="btn btn-primary"
      >
        Go to Dashboard
      </button>
      <button 
        onclick="window.disconnectWallet()" 
        class="btn btn-secondary">
        Sign Out
      </button>
    </div>
  `;

      window.showSuccessAndRedirect = (url) => {
        const container = document.querySelector('.access-message');
        container.innerHTML = `
      <div class="alert-check success-transition">
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" width="20" height="20">
          <path d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
        <p>Access granted! Redirecting to dashboard...</p>
      </div>
    `;

        // Redirect after a short delay to show the success message
        setTimeout(() => {
          window.location.href = url;
        }, 1500);
      };

    } else {
      contentToShow = `
            <div class="error-message">
                <p>❌ Your wallet is connected to a different address:</p>
                 <p class="suggestion">
                    Perhaps you were looking for

                  <a href="https://${currentAddress.toLowerCase()}.gated.so">
                ${currentAddress.toLowerCase()}.gated.so
            </a>

                </p>
                <div class="action-buttons">
                    <button onclick="window.connectWallet(true)" class="primary-btn">
                        Switch Account
                    </button>
                </div>
            </div>
        `;
    }

    const styles = `
  .claim-container {
    max-width: 32rem;
    margin: 0 auto;
    padding: 1.5rem;
  }

  .claim-card {
    background: white;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1);
    border: 1px solid hsl(240 5.9% 90%);
  }

  .card-header {
    padding: 20px;
  }

  .card-title {
    padding:20px;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    margin: 0;
    color: hsl(240 3.8% 46.1%);
  }

  .card-content {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .text-muted {
    color: hsl(240 3.8% 46.1%);
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }

  .address-code {
    font-family: ui-monospace, monospace;
    font-size: 0.875rem;
    background: hsl(240 4.8% 95.9%);
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    display: inline-block;
    color: hsl(240 5.9% 10%);
  }

  @media (max-width: 640px) {
    .claim-container {
      padding: 1rem;
    }
  }
`;

    // Add the styles to the document
    if (!document.getElementById('claim-page-styles')) {
      const styleSheet = document.createElement('style');
      styleSheet.id = 'claim-page-styles';
      styleSheet.textContent = styles;
      document.head.appendChild(styleSheet);
    }
    this.mainContent.innerHTML = `
  <div class="claim-container">
    <div class="claim-card">
        <h1 class="card-title">Claim This Page</h1>
      <div class="card-content">
        <div class="address-section">
          <p class="text-muted">This page belongs to STX address:</p>
          <code class="address-code">${stxAddress}</code>
        </div>
        ${contentToShow}
      </div>
    </div>
  </div>
    <img src=${gatedLogo} alt="Gated Logo" class="logo" />
`;
  }

  hideCreatePageModal() {
    const modal = document.getElementById('create-page-modal');
    const form = document.getElementById('create-page-form');
    if (modal) {
      modal.style.display = 'none';
      if (form) {
        form.reset();
        // Reset the counter displays
        document.getElementById('title-counter').textContent = '0';
        document.getElementById('description-counter').textContent = '0';
      }
    }
  }
  showCreatePageModal() {
    const MAX_TITLE_LENGTH = 64;
    const MAX_DESCRIPTION_LENGTH = 256;

    // First, inject the modal HTML if it doesn't exist
    if (!document.getElementById('create-page-modal')) {
      const modalHTML = `
      <div id="create-page-modal" class="modal fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center" style="display: none;">
        <div class="modal-content bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
          <h3>Create New Page</h3>
          <div id="form-errors" class="text-red-500 mb-4"></div>
          <form id="create-page-form" class="space-y-4">
            <div>
              <div class="create-page-title">Page Title</div>
              <input 
                type="text" 
                name="title" 
                id="title"
                maxlength="${MAX_TITLE_LENGTH}"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="Enter page title" 
                required
              >
              <div class="char-count-title"><span id="title-counter">0</span>/${MAX_TITLE_LENGTH}</div>
            </div>
            <div>
              <div class="create-page-description">Description</div>
              <textarea
                name="description" 
                id="description"
                maxlength="${MAX_DESCRIPTION_LENGTH}"
                class="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                rows="3"
                placeholder="Enter page description"
                required
              ></textarea>
              <div class="char-count-title"><span id="description-counter">0</span>/${MAX_DESCRIPTION_LENGTH}</div>
            </div>
            <div class="create-page-buttons">
              <button 
                type="button" 
                class="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                onclick="window.app.hideCreatePageModal()"
              >
                Cancel
              </button>
              <button 
                type="submit" 
                class="px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md hover:bg-indigo-700"
              >
                Create Page
              </button>
            </div>
          </form>
        </div>
      </div>
    `;
      document.body.insertAdjacentHTML('beforeend', modalHTML);

      // Setup one-time event listeners
      const modal = document.getElementById('create-page-modal');
      const form = document.getElementById('create-page-form');

      // Character counters
      ['title', 'description'].forEach(id => {
        const input = document.getElementById(id);
        const counter = document.getElementById(`${id}-counter`);
        input.addEventListener('input', () => {
          counter.textContent = input.value.length;
        });
      });

      // Form submission
      form.addEventListener('submit', async (e) => {
        e.preventDefault();
        const errorContainer = document.getElementById('form-errors');
        errorContainer.innerHTML = '';

        try {
          const stxAddress = this.auth.userSession.loadUserData().profile.stxAddress.mainnet;
          const title = form.querySelector('input[name="title"]').value.trim();
          const description = form.querySelector('textarea[name="description"]').value.trim();
          const slug = title.toLowerCase().replace(/[^a-z0-9]+/g, '-');

          if (title.length > MAX_TITLE_LENGTH) {
            errorContainer.innerHTML = `Title must be ${MAX_TITLE_LENGTH} characters or less`;
            return;
          }

          if (description.length > MAX_DESCRIPTION_LENGTH) {
            errorContainer.innerHTML = `Description must be ${MAX_DESCRIPTION_LENGTH} characters or less`;
            return;
          }

          if (!title || !description) {
            errorContainer.innerHTML = 'Please fill in all fields';
            return;
          }

          if (slug.length > MAX_TITLE_LENGTH) {
            errorContainer.innerHTML = 'Title generates too long of a URL after conversion';
            return;
          }

          localStorage.setItem('pendingPage', JSON.stringify({ title, description }));
          modal.style.display = 'none';
          form.reset();
          this.showPublishConfirmation(title, description, slug, stxAddress);
        } catch (error) {
          errorContainer.innerHTML = error.message;
        }
      });

      // Modal close handlers
      modal.addEventListener('click', (e) => {
        if (e.target === modal) {
          modal.style.display = 'none';
          form.reset();
        }
      });

      document.addEventListener('keydown', (e) => {
        if (e.key === 'Escape' && modal.style.display === 'flex') {
          modal.style.display = 'none';
          form.reset();
        }
      });
    }

    // Create button click handler
    const createBtn = document.querySelector('.create-btn');
    if (createBtn) {
      createBtn.onclick = () => {
        const modal = document.getElementById('create-page-modal');
        modal.style.display = 'flex';
        modal.querySelector('input[name="title"]').focus();
      };
    }
  }


  showPublishConfirmation(title, description, slug, stxAddress) {
    const confirmModal = document.createElement('div');
    confirmModal.id = 'confirm-modal';
    confirmModal.className = 'modal';

    confirmModal.innerHTML = `
    <div class="modal-content">
      <h3>Confirm Page Creation</h3>
      <p>You are about to create a new page:</p>
      <p><strong>Title:</strong> ${title}</p>
      <p><strong>Description:</strong> ${description}</p>
      <p><strong>URL:</strong> ${stxAddress}.gated.so/#/${slug}</p>
      <div class="button-group">
        <button type="button" class="cancel-btn">Cancel</button>
        <button type="button" class="confirm-btn">Create Page</button>
      </div>
    </div>
  `;

    document.body.appendChild(confirmModal);

    // Setup confirmation handlers
    const cancelBtn = confirmModal.querySelector('.cancel-btn');
    const confirmBtn = confirmModal.querySelector('.confirm-btn');

    const closeConfirmModal = () => {
      document.body.removeChild(confirmModal);
    };

    cancelBtn.addEventListener('click', closeConfirmModal);

    // Handle confirmation and contract call
    confirmBtn.addEventListener('click', async () => {
      try {
        const userSession = new UserSession();
        const userData = userSession.loadUserData();
        const senderAddress = userData.profile.stxAddress.mainnet;
        const contract = 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5.gated-pages-001';
        const metadataUri = `https://${senderAddress}.gated.so/#/${slug}`;
        const gatedFee = 1000000;

        // Prepare contract call
        const functionArgs = [
          stringAsciiCV(title),
          stringAsciiCV(description),
          stringAsciiCV(metadataUri)
        ];

        const postConditions = [
          Pc.principal(senderAddress).willSendEq(gatedFee).ustx()
        ];

        const options = {
          network: STACKS_MAINNET,
          contractAddress: contract.split('.')[0],
          contractName: contract.split('.')[1],
          functionName: 'add-page',
          functionArgs: functionArgs,
          postConditions: postConditions,
          onFinish: data => {
            console.log('Transaction:', data);
            localStorage.removeItem('pendingPage');
            window.location.href = `/#/${slug}?tx=${data.txId}`;
          }
        };

        // Make the contract call
        await openContractCall(options);

      } catch (error) {
        console.error('Error creating page:', error);
        alert(error.message);
      } finally {
        closeConfirmModal();
      }
    });

    // Close on click outside
    confirmModal.addEventListener('click', (e) => {
      if (e.target === confirmModal) {
        closeConfirmModal();
      }
    });
  }

  renderAdminDashboard(stxAddress) {
    console.log('Rendering admin dashboard for:', stxAddress);

    this.mainContent.innerHTML = `
        <div class="admin-dashboard">
            <header class="dashboard-header">
              <div class="dashboard-header-text-container">
                <div class="dashboard-header-text">
                <a href="https://${stxAddress}.gated.so" style="text-decoration: none;">
                <img src="${gatedLogo}" div class="logo">
               
</a>
                </div>
                
                </div> 
                <div class="wallet-dropdown">

                    <div id="wallet-dropdown-content" class="wallet-dropdown-content">
                        <button onclick="window.disconnectWallet()" class="dropdown-item"> ${this.auth.formatAccountNumber(stxAddress).toUpperCase()}</button>
                    </div>
                </div>
            </header>

            <div class="dashboard-content">
                <section class="sidebar">
                   <div class="dashboard-header-text">
                        Dashboard
                      </div>

                <hr style="/* border-bottom: navajowhite; */
                  background-color: #e8e8e8;
                  width: 100%;
                  height: 1px;
                  border: none;">
                  <div class="sidebar-header">
                  Menu
                  </div>
                  <div class="sidebar-list">
                    <div class="item-pages">Pages</div>
                    <div class="item-media">Media</div>
                  </div>
                </section>

                <section class="pages-section">
                    <div class="section-header">
                        <div class="section-header-text">
                          <h2>Your Pages</h2>
                          <div class="section-header-subtitle">Pages you own. Secured by blockchain.</div>
                        </div>
                          <button class="create-btn">Create New Page</button>
                    </div>
                    <div id="pages-list" class="pages-list">
                        Loading pages from Blockchain...
                    </div>
                </section>
            </div>
        </div>
    `;

    // Add dropdown functionality after rendering
    this.setupWalletDropdown();

    // Initialize tab switching
    this.initializeSidebarTabs(stxAddress);

    // Initialize other dashboard functionality
    this.loadPages(stxAddress);
    this.showCreatePageModal();

  }

  initializeSidebarTabs(stxAddress) {
    // First verify the elements exist
    const pagesTab = this.mainContent?.querySelector('.item-pages');
    const mediaTab = this.mainContent?.querySelector('.item-media');
    const contentSection = this.mainContent?.querySelector('.pages-section');

    if (!pagesTab || !mediaTab || !contentSection) {
      console.error('Required elements not found for tab initialization');
      return;
    }

    // Initialize music player if it doesn't exist
    if (!window.musicPlayer) {
      window.musicPlayer = {
        isInitialized: false,
        currentTrackIndex: 0,
        audioPlayer: new Audio(),


        init() {
          if (this.isInitialized) return;
          this.setupAudioPlayer();
          this.isInitialized = true;
        },

        setupAudioPlayer() {
          this.audioPlayer.addEventListener('timeupdate', this.updateProgress.bind(this));
          this.audioPlayer.addEventListener('ended', this.handleTrackEnd.bind(this));
        },

        setCurrentTrack(index) {
          this.currentTrackIndex = index;
        },


        togglePlayPause() {
          if (this.audioPlayer.paused) {
            this.audioPlayer.play();
          } else {
            this.audioPlayer.pause();
          }
          this.updatePlayPauseButton();
        },

        updateProgress() {
          const progressBar = document.getElementById('progress-bar');
          const duration = document.getElementById('song-duration');

          if (progressBar && !isNaN(this.audioPlayer.duration)) {
            const percentage = (this.audioPlayer.currentTime / this.audioPlayer.duration) * 100;
            progressBar.value = percentage;
          }

          if (duration) {
            // Calculate remaining time instead of elapsed time
            const remainingTime = this.audioPlayer.duration - this.audioPlayer.currentTime;
            duration.textContent = this.formatTime(remainingTime);
          }
        },


        updatePlayerUI(song) {
          const albumArt = document.getElementById('album-art');
          const trackInfo = document.getElementById('track-info');
          const stickyPlayer = document.getElementById('music-player-sticky');

          if (albumArt) albumArt.src = song.imgUrl;
          if (trackInfo) trackInfo.textContent = `${song.songArtist} - ${song.songName}`;
          if (stickyPlayer) stickyPlayer.style.display = 'flex';
        },

        formatTime(seconds) {
          if (isNaN(seconds)) return "0:00";

          // Round down to nearest second to avoid fractional seconds
          seconds = Math.floor(seconds);

          const minutes = Math.floor(seconds / 60);
          const remainingSeconds = seconds % 60;

          // Format as M:SS
          return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
        },
      };
    }

    const showMediaContent = () => {
      // First ensure musicPlayer exists
      if (!window.musicPlayer) {
        console.error('Music player not initialized');
        return;
      }

      contentSection.innerHTML = `
    <div class="section-header">
      <div class="section-header-text">
        <h2>${musicData.title}</h2>
        <div class="section-header-subtitle">${musicData.subtitle}</div>
      </div>
    </div>
    <div class="media-container">
      <div class="media-grid" id="media-grid"></div>
    </div>
    <div id="music-player-sticky" class="sticky-player">
      <div class="player-content">
        <img id="album-art" src="" alt="Album Art" class="album-art">
        <div class="track-info-container">
          <div id="track-info" class="track-info"></div>
          <div class="player-controls">
            <button id="prev-btn" class="control-btn">
              <i class="fa-solid fa-backward"></i>
            </button>
            <button id="play-pause-btn" class="control-btn">
              <i class="fa-solid fa-play"></i>
            </button>
            <button id="next-btn" class="control-btn">
              <i class="fa-solid fa-forward"></i>
            </button>
             <input 
              type="range" 
              id="volume-control" 
              min="0" 
              max="100" 
              value="50" 
              class="volume-slider"
            >
          </div>
        </div>
        <div class="progress-container">
          <input type="range" id="progress-bar" min="0" max="100" value="0">
          <span id="song-duration"></span>
        </div>
      </div>
    </div>
  `;




      requestAnimationFrame(() => {
        // Initialize grid first
        this.initializeMediaGrid();

        // Initialize music player if needed
        if (!window.musicPlayer.isInitialized) {
          window.musicPlayer.init();
        }


      });
    };



    // Add this helper method
    function updateStickyPlayerUI(controls) {
      const currentSong = musicData.songs[window.musicPlayer.currentTrackIndex];

      // Update album art
      if (controls.albumArt && currentSong.imgUrl) {
        controls.albumArt.src = currentSong.imgUrl;
      }

      // Update track info
      if (controls.trackInfo) {
        controls.trackInfo.textContent = `${currentSong.songArtist} - ${currentSong.songName}`;
      }

      // Update play/pause button
      if (controls.playPauseBtn) {
        controls.playPauseBtn.innerHTML = window.musicPlayer.audioPlayer.paused ?
          '<i class="fa-solid fa-play"></i>' :
          '<i class="fa-solid fa-pause"></i>';
      }

      // Show sticky player
      const stickyPlayer = document.getElementById('music-player-sticky');
      if (stickyPlayer) {
        stickyPlayer.style.display = 'flex';
      }
    }

    // Add this helper method
    function updateStickyPlayerProgress(controls) {
      if (!window.musicPlayer.audioPlayer) return;

      // Update progress bar
      if (controls.progressBar) {
        const percentage = (window.musicPlayer.audioPlayer.currentTime / window.musicPlayer.MAX_PLAY_TIME) * 100;
        controls.progressBar.value = percentage;
      }

      // Update duration display
      if (controls.durationDisplay) {
        const timeLeft = Math.ceil(window.musicPlayer.MAX_PLAY_TIME - window.musicPlayer.audioPlayer.currentTime);
        const minutes = Math.floor(timeLeft / 60);
        const seconds = timeLeft % 60;
        controls.durationDisplay.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
      }
    }

    // Add this helper method
    function addStickyPlayerStyles() {
      if (document.getElementById('sticky-player-styles')) return;

      const styles = `
    .sticky-player {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: white;
      padding: 1rem;
      box-shadow: 0 -2px 10px rgba(0,0,0,0.1);
      z-index: 1000;
    }

    .player-content {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 1rem;
      align-items: center;
    }

    .album-art {
      width: 50px;
      height: 50px;
      border-radius: 4px;
      object-fit: cover;
    }

    .track-info-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .player-controls {
      display: flex;
      gap: 1rem;
      align-items: center;
    }

    .control-btn {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem;
      transition: opacity 0.2s;
    }

    .control-btn:hover {
      opacity: 0.8;
    }

    .progress-container {
      grid-column: 1 / -1;
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    #progress-bar {
      flex: 1;
    }

    #song-duration {
      min-width: 4ch;
      text-align: right;
    }
  `;

      const styleSheet = document.createElement('style');
      styleSheet.id = 'sticky-player-styles';
      styleSheet.textContent = styles;
      document.head.appendChild(styleSheet);
    };

    const showPagesContent = () => {
      contentSection.innerHTML = `
      <div class="section-header">
        <div class="section-header-text">
          <h2>Your Pages</h2>
          <div class="section-header-subtitle">Pages you own. Secured by blockchain.</div>
        </div>
        <button class="create-btn">Create New Page</button>
      </div>
      <div id="pages-list" class="pages-list">
        Loading pages from Blockchain...
      </div>
    `;

      this.loadPages(stxAddress);
      this.showCreatePageModal();
    };

    // Add event listeners for tabs
    const handlePagesClick = (e) => {
      e.preventDefault();
      pagesTab.classList.add('active');
      mediaTab.classList.remove('active');
      showPagesContent();
    };

    const handleMediaClick = (e) => {
      e.preventDefault();
      mediaTab.classList.add('active');
      pagesTab.classList.remove('active');
      showMediaContent();
    };

    // Remove existing listeners if any
    pagesTab.removeEventListener('click', handlePagesClick);
    mediaTab.removeEventListener('click', handleMediaClick);

    // Add new listeners
    pagesTab.addEventListener('click', handlePagesClick);
    mediaTab.addEventListener('click', handleMediaClick);

    // Add styles
    this.addMediaStyles();
  }
  // Add this new method to your class


  // First, let's create a mapping between NFT names and track data
  TRACK_MAPPING = {
    'radio-silence': {
      songName: "Radio Silence",
      songArtist: "Josie Field",
      imgUrl: track1Image,
      audioUrl: track1Audio
    },
    'you-make-it-alright': {
      songName: "You Make It Alright",
      songArtist: "Ard Matthews",
      imgUrl: track2Image,
      audioUrl: track2Audio
    },
    'mortals': {
      songName: "Mortals",
      songArtist: "Arno Carstens",
      imgUrl: track3Image,
      audioUrl: track3Audio
    },
    'buzz-head': {
      songName: "Buzz Head",
      songArtist: "Kahn Morbee",
      imgUrl: track4Image,
      audioUrl: track4Audio
    },
    'grey': {
      songName: "Grey",
      songArtist: "Laurie Levine",
      imgUrl: track5Image,
      audioUrl: track5Audio
    },
    'exile': {
      songName: "Exile",
      songArtist: "Jacques Moolman",
      imgUrl: track6Image,
      audioUrl: track6Audio
    },
    'i-shouldnt-break': {
      songName: "I Shouldn't Break",
      songArtist: "Evert Snyman",
      imgUrl: track7Image,
      audioUrl: track7Audio
    },
    'bbq-to-braai': {
      songName: "BBQ to Braai",
      songArtist: "Xander",
      imgUrl: track8Image,
      audioUrl: track8Audio
    }
  };
  async initializeMediaGrid() {
    const mediaGrid = document.getElementById('media-grid');
    if (!mediaGrid || !musicData) return;

    try {
      const userData = this.auth.userSession.loadUserData();
      const userAddressMainnet = userData.profile.stxAddress.mainnet;

      const nftHoldingsUrl = `https://stacks-node-api.mainnet.stacks.co/extended/v1/tokens/nft/holdings?principal=${userAddressMainnet}&limit=50`;
      const holdingsResponse = await fetch(nftHoldingsUrl);
      if (!holdingsResponse.ok) throw new Error('Failed to fetch NFT holdings');

      const holdingsData = await holdingsResponse.json();
      const ownedNFTs = new Set();

      if (holdingsData.results) {
        for (const nft of holdingsData.results) {
          const matches = nft.asset_identifier.match(/^(SP[A-Z0-9]+)\.([^:]+)::([^:]+)$/);
          if (matches &&
            matches[1] === 'SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5' &&
            matches[2] === 'gated-005') {
            ownedNFTs.add(matches[3]);
          }
        }
      }

      const ownedTracks = Array.from(ownedNFTs)
        .map(nftName => this.TRACK_MAPPING[nftName])
        .filter(track => track);

      if (ownedTracks.length === 0) {
        mediaGrid.innerHTML = `
                <div class="no-tracks-message">
                    <p>No owned tracks found. Purchase tracks to start listening!</p>
                    <div class="debug-info" style="font-family: monospace; font-size: 12px; margin-top: 20px; padding: 10px; background: #f5f5f5;">
                        <p>Debug Info:</p>
                        <p>Found NFTs (${ownedNFTs.size}): ${Array.from(ownedNFTs).join(', ')}</p>
                        <p>Looking for tracks: ${musicData.songs.map(s => s.songName.toLowerCase().replace(/[^a-z0-9]+/g, '-')).join(', ')}</p>
                        <p>Contract: SP2BRB6P0BK6T35DHTGXCV6MZ5TGRN5E0RKZ1T8B5.gated-005</p>
                    </div>
                </div>
            `;
        return;
      }

      const testMode = false;
      const hasCompleteCollection = testMode ? true : ownedTracks.length === 8;
      console.log(`Collection Status: ${ownedTracks.length}/8 tracks owned`);
      console.log('Complete collection:', hasCompleteCollection);

      const gridHTML = `
            ${hasCompleteCollection ? `
                <div class="media-item" data-index="album">
                    <img src="${albumImage}" alt="Complete Album" />
                    <div class="play-overlay">
                        <i class="fa-solid fa-crown fa-2x" style="color: gold;"></i>
                    </div>
                    <div class="song-info">
                        <h3>Complete Collection</h3>
                        <p>${ownedTracks.length}/8 Tracks Owned</p>
                    </div>
                </div>
            ` : ''}
            ${ownedTracks.map((song, index) => `
                <div class="media-item" data-index="${index}">
                    <img src="${song.imgUrl}" alt="${song.songName}" />
                    <div class="play-overlay">
                        <i class="fa-solid fa-play fa-2x play-icon" style="color: white;"></i>
                        <i class="fa-solid fa-pause fa-2x pause-icon" style="color: white; display: none;"></i>
                    </div>
                    <div class="song-info">
                        <h3>${song.songName}</h3>
                        <p>${song.songArtist}</p>
                    </div>
                </div>
            `).join('')}
        `;

      mediaGrid.innerHTML = gridHTML;

      if (!window.musicPlayer.isInitialized) {
        window.musicPlayer.init();
      }

      if (ownedTracks.length > 0) {
        window.musicPlayer.loadTrack(0, ownedTracks, true);
      }

      const mediaItems = mediaGrid.querySelectorAll('.media-item:not(.non-playable)');
      mediaItems.forEach(item => {
        item.addEventListener('click', () => {
          const index = parseInt(item.dataset.index);
          if (!window.musicPlayer) return;

          if (index === 0 && !window.musicPlayer.audioPlayer.src) {
            window.musicPlayer.loadTrack(0, ownedTracks, true);
          }

          const isCurrentTrack = window.musicPlayer.currentTrackIndex === index;
          const isPlaying = !window.musicPlayer.audioPlayer.paused;

          if (isCurrentTrack && isPlaying) {
            window.musicPlayer.togglePlayPause();
          } else if (isCurrentTrack && !isPlaying) {
            window.musicPlayer.togglePlayPause();
          } else {
            window.musicPlayer.loadTrack(index, ownedTracks, true);
            setTimeout(() => window.musicPlayer.togglePlayPause(), 100);
          }
        });
      });

    } catch (error) {
      console.error('Error in initializeMediaGrid:', error);
      mediaGrid.innerHTML = `
            <div class="error-message">
                <p>Error loading your music collection. Please try again later.</p>
                <p>Error: ${error.message}</p>
            </div>
        `;
    }
  }


  updateStickyPlayerUI(controls) {
    if (!window.musicPlayer?.audioPlayer) return;

    const currentSong = musicData.songs[window.musicPlayer.currentTrackIndex];

    if (controls.albumArt) {
      controls.albumArt.src = currentSong.imgUrl;
    }

    if (controls.trackInfo) {
      controls.trackInfo.textContent = `${currentSong.songArtist} - ${currentSong.songName}`;
    }

    if (controls.playPauseBtn) {
      controls.playPauseBtn.innerHTML = window.musicPlayer.audioPlayer.paused ?
        '<i class="fa-solid fa-play"></i>' :
        '<i class="fa-solid fa-pause"></i>';
    }
  }

  // Update the updateStickyPlayerProgress method in your Router class
  updateStickyPlayerProgress(controls) {
    if (!window.musicPlayer?.audioPlayer?.duration) return;

    const currentTime = window.musicPlayer.audioPlayer.currentTime;
    const duration = window.musicPlayer.audioPlayer.duration;

    if (controls.progressBar) {
      controls.progressBar.value = (currentTime / duration) * 100;
    }

    if (controls.durationDisplay) {
      // Calculate remaining time
      const remainingTime = duration - currentTime;
      const minutes = Math.floor(remainingTime / 60);
      const seconds = Math.floor(remainingTime % 60);
      controls.durationDisplay.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`;
    }
  }

  // Separate method for styles
  addMediaStyles() {
    if (document.getElementById('media-grid-styles')) return;

    const styles = `
        .media-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding: 20px;
        }

        .media-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
            gap: 20px;
            padding: 20px 0;
        }

        .media-item {
            position: relative;
            aspect-ratio: 1;
            border-radius: 8px;
            overflow: hidden;
            cursor: pointer;
            transition: transform 0.2s ease, box-shadow 0.2s ease;
            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }

        .media-item:hover {
            transform: translateY(-5px);
            box-shadow: 0 5px 15px rgba(0,0,0,0.2);
        }

        .media-item img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .media-item .play-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0, 0, 0, 0.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        .media-item:hover .play-overlay {
            opacity: 1;
        }

        .media-item .song-info {
            color: white;
            text-align: center;
            padding: 10px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.7);
        }

        .media-item .song-info h3 {
            margin: 0;
            font-size: 0.9em;
            font-weight: 500;
        }

        .media-item .song-info p {
            margin: 5px 0 0;
            font-size: 0.8em;
            opacity: 0.8;
        }
    `;

    const styleSheet = document.createElement('style');
    styleSheet.id = 'media-grid-styles';
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);
  }

  // Add this new method to handle dropdown setup
  setupWalletDropdown() {
    console.log('Setting up wallet dropdown');

    const dropdownBtn = document.getElementById('wallet-dropdown-btn');
    const dropdownContent = document.getElementById('wallet-dropdown-content');

    if (!dropdownBtn || !dropdownContent) {
      console.error('Dropdown elements not found');
      return;
    }

    // Toggle dropdown on button click
    dropdownBtn.addEventListener('click', (e) => {
      e.stopPropagation();
      dropdownContent.classList.toggle('show');
      console.log('Dropdown toggled');
    });

    // Close dropdown when clicking outside
    document.addEventListener('click', (e) => {
      if (!dropdownBtn.contains(e.target)) {
        dropdownContent.classList.remove('show');
      }
    });

    // Prevent dropdown from closing when clicking inside content
    dropdownContent.addEventListener('click', (e) => {
      e.stopPropagation();
    });
  }

  async loadUserPages(stxAddress) {
    try {
      const pagesList = document.getElementById('pages-list');
      const pages = await window.fetchPagesForAddress(stxAddress);

      if (pages.length === 0) {
        pagesList.innerHTML = `
        <div class="empty-state">
          <p>You haven't created any pages yet.</p>
          <p>Click "Create New Page" to get started.</p>
        </div>
      `;
        return;
      }

      pagesList.innerHTML = pages.map(page => {
        const pageUrl = `${window.location.protocol}//${stxAddress}.${window.location.host}/#/${page.title}`;

        return `
          <div class="page-card">
            <div class="page-header">
              <h3 class="page-title">${page.title}</h3>
              <div class="url-container flex items-center gap-2">
                <p class="page-url truncate">${pageUrl}</p>
                <button
                  onclick="copyPageUrl('${pageUrl}')" 
                  class="copy-btn flex items-center p-2 hover:bg-gray-100 rounded"
                  title="Copy URL"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                    <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
                    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div class="page-actions">
              <a href="${page.title}" class="action-btn btn-primary">View Page</a>
            </div>
          </div>
        `;
      }).join('');

      // Add the copyPageUrl function to window
      window.copyPageUrl = async (url) => {
        try {
          await navigator.clipboard.writeText(url);
          const btn = event.target.closest('.copy-btn');

          // Show feedback
          const originalHTML = btn.innerHTML;
          btn.innerHTML = `
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <polyline points="20 6 9 17 4 12"></polyline>
            </svg>
          `;

          // Reset after 2 seconds
          setTimeout(() => {
            btn.innerHTML = originalHTML;
          }, 2000);
        } catch (err) {
          console.error('Failed to copy:', err);
        }
      };

    } catch (error) {
      console.error('Error loading user pages:', error);
      const pagesList = document.getElementById('pages-list');
      pagesList.innerHTML = `
        <div class="empty-state">
          <p>Error loading pages. Please try again later.</p>
        </div>
      `;
    }
  }

  async renderUserPage(stxAddress, pageTitle, txId) {
    try {
      // Add animation style first, before any content rendering
      const style = document.createElement('style');
      style.textContent = `
        .profile-outer-container {
          transform-origin: center;
          transition: transform 0.1s ease;
        }
        .verification-status {
          margin-top: 1rem;
          text-align: center;
        }
        .verification-link {
          color: #1da1f2;
          text-decoration: none;
          display: inline-block;
          padding: 8px 16px;
          border-radius: 20px;
          background: rgba(29, 161, 242, 0.1);
        }
        .verification-link:hover {
          background: rgba(29, 161, 242, 0.2);
        }
      `;
      document.head.appendChild(style);

      // Create verification link element
      const verificationElement = txId ?
        `<div class="verification-status">
           <a href="https://explorer.stacks.co/txid/${txId}?chain=mainnet" 
              target="_blank" 
              class="verification-link">
             View on Blockchain ✓
           </a>
         </div>` :
        `<div class="verification-status">
           <span style="color: #666;">Page Not Yet Claimed</span>
         </div>`;

      // Owner-specific profile content
      const ownerContent = txId ? `
      <div class="profile-section">
        <div id="profileOuterContainer" class="profile-outer-container">
          <div id="profileInnerContainer" class="profile-inner-container">
            <div id="profilePhoto" class="profile-photo">
              <img src=${this.blocktuneLogo} alt="Profile Photo" class="logo" style="width:150px;" />
            </div>
            <div id="tipText" class="tip-text">Tip 1 STX</div>
          </div>
        </div>
        <audio id="clickSound" src="https://www.soundjay.com/buttons/sounds/button-50.mp3" preload="auto"></audio>
        <div id="bnsv2NameDisplay" class="bns-name">
          <a href="https://x.com/${stxAddress}" target="_blank">@${stxAddress}</a>
        </div>
        ${verificationElement}
      </div>
      <div class="page-description">
        <i>${pageTitle}</i> description goes here. 
        You can customize this description for each page.
      </div>
    ` : `
      <div class="page-section" style="text-align: center; margin: 40px 0;">
        <h1 style="font-size: 32px; margin-bottom: 40px;">${pageTitle}</h1>
        <p style="margin-bottom: 20px">
          This page belongs to 
          <code class="address-code" style="font-family: monospace;">
            ${stxAddress}
          </code>
        </p>
        ${verificationElement}
      </div>
    `;



      this.mainContent.innerHTML = `
      <div id="main-container" class="main-container flex">
        ${ownerContent}
        <div id="store-container"></div>
      </div>
    `;

      // Initialize profile exactly like in the specific page route
      if (txId) {
        setTimeout(() => {
          try {
            if (!window.profilePhoto) {
              window.profilePhoto = new ProfilePhoto();
            }
          } catch (error) {
            console.error('Error initializing profile:', error);
          }
        }, 0);
      }

      this.initializeAudioControls();
    } catch (error) {
      console.error('Error rendering user page:', error);
      this.mainContent.innerHTML = `
      <div class="error-message">Failed to load page content. Please try again later.</div>
    `;
    }
  }

  initializeAudioControls() {
    const playPauseBtn = document.getElementById('play-pause-btn');
    const prevBtn = document.getElementById('prev-btn');
    const nextBtn = document.getElementById('next-btn');

    playPauseBtn?.addEventListener('click', togglePlayPause);
    prevBtn?.addEventListener('click', playPreviousTrack);
    nextBtn?.addEventListener('click', playNextTrack);
  }

  // Add this helper method if you don't already have it
  escapeHtml(unsafe) {
    if (!unsafe) return '';
    return unsafe
      .toString()
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  async loadPageContent(stxAddress, pageTitle) {
    try {
      const nftContent = document.getElementById('nft-content');
      const content = await window.fetchPageContent(stxAddress, pageTitle);

      nftContent.innerHTML = `
        <div class="nft-card">
          <h2>${content.nftName}</h2>
          <p>${content.description}</p>
          <button onclick="window.mintNFT('${content.id}')">
            Mint NFT (${content.price} STX)
          </button>
        </div>
      `;
    } catch (error) {
      console.error('Error loading page content:', error);
    }
  }
}

// Add the copy function
async function copyPageUrl(url) {
  try {
    await navigator.clipboard.writeText(url);
    const btn = event.target.closest('.copy-btn');

    // Show feedback
    const originalHTML = btn.innerHTML;
    btn.innerHTML = `
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="green" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
    `;

    // Reset after 2 seconds
    setTimeout(() => {
      btn.innerHTML = originalHTML;
    }, 2000);
  } catch (err) {
    console.error('Failed to copy:', err);
  }
}

// Initialize router
window.router = new Router();

// Export for module usage
export default Router;